export const HIDDEN_FORM_ERROR = 'hide_form_error';
export const SHOW_FORM_ERROR = 'show_form_error';
export const SHOW_SUCCESS_MESSAGE_FORM = 'show_form_success';
export const HIDDEN_SUCCESS_MESSAGE_FORM = 'hide_form_success';

export const showMessageError = payload => {
  return {
    type: SHOW_FORM_ERROR,
    payload,
  };
};

export const hiddenMessageError = payload => {
  return {
    type: HIDDEN_FORM_ERROR,
    payload,
  };
};

export const hideMessageSuccessForm = payload => {
  return {
    type: HIDDEN_SUCCESS_MESSAGE_FORM,
    payload,
  };
};

export const showMessageSuccess = payload => {
  return {
    type: SHOW_SUCCESS_MESSAGE_FORM,
    payload,
  };
};
