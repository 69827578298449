import { api } from './api';

export class TicketApi {
  static createTicket = async ticket => api.post('tickets', ticket);

  static updateTicket = async (id, ticket) => api.put(`tickets/${id}`, ticket);

  static deleteTicket = async id => api.delete(`tickets/${id}`);

  static fetchTicket = async id => api.get(`tickets/${id}`);

  static fetchAllTickets = async (query = '') => api.get(`tickets${query}`);

  static closeTicket = async (id, obj) => api.put(`tickets/${id}/close`, obj);

  static reopenTicket = async (id, obj) => api.put(`tickets/${id}/reopen`, obj);

  static fetchOperationTypes = async () => api.get('tickets/operation_types');
}
