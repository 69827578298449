import { all, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  HIDDEN_FORM_ERROR,
  SHOW_FORM_ERROR,
  SHOW_SUCCESS_MESSAGE_FORM,
  HIDDEN_SUCCESS_MESSAGE_FORM,
} from '../actions/FormCreate';

function testeTrue({ action }) {}

function testeFalse({ payload }) {}
function showMessageSuccess({ action }) {}

function hiddenMessageSuccess({ payload }) {}

export function* hiddenErrorSaga() {
  yield takeLatest(HIDDEN_FORM_ERROR, testeTrue);
}

export function* showMessageSuccessSaga() {
  yield takeLatest(SHOW_SUCCESS_MESSAGE_FORM, showMessageSuccess);
}

export function* hiddenMessageSuccessSaga() {
  yield takeLatest(HIDDEN_SUCCESS_MESSAGE_FORM, hiddenMessageSuccess);
}

export function* createErrorSaga() {
  yield takeLatest(SHOW_FORM_ERROR, testeFalse);
}

export default function* rootSaga() {
  yield all([
    fork(createErrorSaga),
    fork(createErrorSaga),
    fork(showMessageSuccessSaga),
    fork(hiddenMessageSuccessSaga),
  ]);
}
