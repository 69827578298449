import { api } from './api';

export class PermissionService {
  // Cria a relação do usuário com 1 ou + Clientes
  static createCustomerUsers = customerUsers =>
    api.post('customer_users', customerUsers);

  // retorna a relação do usuário com 1 ou + Clientes
  static fetchCustomerUsers = () => api.get('customer_users');

  // Cria a relação do usuário com 1 ou + Contratos do(s) Cliente(s) previamente relacionados.
  static createContractUser = contractUser => {
    return api.post('contract_users', contractUser);
  };

  // retorna a relação do usuário com 1 ou + Contratos do(s) Cliente(s) previamente relacionados.
  static fetchContractUsers = () => api.get('contract_users');

  // Cria a relação do usuário com 1 ou + Unidades dos Contratos previamente relacionados ao(s) Cliente(s).
  static createUnitUser = unitUser => api.post('unit_users', unitUser);

  // retorna a relação do usuário com 1 ou + Unidades do(s) Contrato(s) previamente relacionados ao(s) Cliente(s).
  // É aqui que são gerenciadas as permissões pelo Admin. PAREI AQUI
  static fetchUnitUsers = () => api.get('unit_users');
  // static getSingleUserCustomers = id => api.get(`customer_users/${id}`); DESATIVADO PORQUE NÃO ERA UTILIZADO EM LUGAR ALGUM.
  // static fetchCustomerUser = id => api.get(`customer_users/${id}`);

  //  static createCustomerUserOperators = obj =>
  //  api.post('customer_users', obj);
  // static createOperatorUsers = operatorUser =>
  //  api.post('operator_users', operatorUser);

  static updateUserCustomersPermission = (id, obj) =>
    api.put(`users/${id}/customer_users`, obj);

  static updateUserContractsPermission = (id, obj) => {
    return api.put(`users/${id}/contract_users`, obj);
  };

  static updateUserUnitPermission = (id, obj) => {
    return api.put(`/users/${id}/unit_users`, obj);
  };
}
