import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import { SIGNIN_USER, SIGNOUT_USER } from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  hideAuthLoader,
} from 'actions/Auth';
import { SessionApi } from 'api/SessionApi';
import { UserService } from 'api/UserService';
import { toast } from 'react-toastify';

const sessionRequest = value =>
  SessionApi.createNewSession(value)
    .then(response => response)
    .catch(error => error);

const getUsers = () =>
  UserService.fetchAllUsers()
    .then(value => value)
    .catch(error => error);

const saveUserHeaders = async headers => {
  sessionStorage.setItem('user', JSON.stringify(headers));
  localStorage.setItem('user', JSON.stringify(headers));
};

async function getFormatedCredentials() {
  const userHeaders = sessionStorage.getItem('user');
  const formatedUserHeaders = JSON.parse(userHeaders);
  if (formatedUserHeaders.uid) {
    return formatedUserHeaders;
  }
  return null;
}

function* getSignedUser(uid) {
  try {
    const usersList = yield call(getUsers);

    if (usersList.data) {
      return usersList.data.users.find(item => item.email === uid);
    }
  } catch (error) {
    toast.error('Erro ao entrar no sistema');
    return yield put(hideAuthLoader());
  }
}

function* singInUser({ payload }) {
  try {
    const login = yield call(sessionRequest, payload);

    if (!login.data) {
      const { errors } = login.response.data;

      errors.map(item => toast.error(item));
      return yield put(hideAuthLoader());
    }

    yield call(saveUserHeaders, login.headers);

    const userSigned = yield call(getFormatedCredentials, login.headers);

    const { uid } = userSigned;
    const signedUser = yield call(getSignedUser, uid);

    if (signedUser.id) {
      return yield put(
        userSignInSuccess(
          {
            userinfo: signedUser,
          },
          login.data.user.user_type,
          'app/clientes'
        )
      );
    }
    return yield put(hideAuthLoader());
  } catch (error) {
    if (!error.response) {
      toast.error('Ops! Verifique sua conexão com a internet');
      return yield put(hideAuthLoader());
    }
  }
}

function* singOutUser() {
  const userToken = sessionStorage.getItem('user');
  try {
    if (userToken) {
      yield put(userSignOutSuccess());
      sessionStorage.clear();
      localStorage.clear();
    } else {
      yield put(userSignOutSuccess());
    }
  } catch (error) {
    yield put(showAuthMessage('Erro ao tentar sair'));
  }
}

function* rehydateSaga({ payload }) {
  if (payload) {
    return yield put(hideAuthLoader());
  }
}

export function* persistListning() {
  yield takeLatest('persist/REHYDRATE', rehydateSaga);
}
export function* signInUser() {
  yield takeLatest(SIGNIN_USER, singInUser);
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, singOutUser);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser), fork(persistListning)]);
}
