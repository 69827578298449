export const FETCH_USER = '@user';
export const FETCH_USER_DETAILS_SUCCESS =
  '@userDetails_fetch_user_details_success';
// export const FETCH_USER_DETAILS_FAIL = '@userDetails_fetch_user_details_fail';
export const UPDATE_USER_CONTRACT_PERMISSIONS =
  '@userDetails_update_user_contract_permissions';
export const UPDATE_USER_UNIT_PERMISSIONS =
  '@userDetails_update_user_unit_permissions';

export const fetchUser = user => ({
  type: FETCH_USER,
  payload: user,
});

export const fetchUserDetailsSuccess = user => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload: user,
});

// export const fetchUserDetailsFail = () => ({
//   type: FETCH_USER_DETAILS_FAIL,
// });

export const updateUserContract = contractUserPermission => ({
  type: UPDATE_USER_CONTRACT_PERMISSIONS,
  payload: contractUserPermission,
});

export const updateUserUnit = unitUserPermission => ({
  type: UPDATE_USER_UNIT_PERMISSIONS,
  payload: unitUserPermission,
});

const INITIAL_STATE = {
  user: {},
  loadingFetch: true,
};

export const userPermissionsDuck = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        user: state.user,
        loadingFetch: true,
      };

    // case FETCH_USER_DETAILS_FAIL:
    //   return {
    //     ...state,
    //     user: state.user,
    //     loadingFetch: false,
    //   };

    case UPDATE_USER_CONTRACT_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          contract_users: action.payload,
        },
        loadingFetch: false,
      };
    case UPDATE_USER_UNIT_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          unit_users: action.payload,
        },
        loadingFetch: false,
      };

    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loadingFetch: false,
      };

    default:
      return state;
  }
};
