export const FETCH_CONTRACT_PERMISSIONS_CALL =
  '@contract_save_new_user_contract_call';
export const FETCH_CONTRACT_PERMISSIONS = '@contract_fetch_contract';
export const INCREMENT_CONTRACT = '@contract_increment_contract';
export const CLEAN_CONTRACT = '@contract_clean_contract';
export const UPDATE_USERS_CONTRACT_PERMISSIONS = '@contract_update_contract';
export const SAVE_NEW_CONTRACT = '@contract_save_new_user_contract';
export const UPDATE_CONTRACT_USER = '@contract_user_update';

export const fetchContractsUserPermissionsCall = () => ({
  type: FETCH_CONTRACT_PERMISSIONS_CALL,
});

export const fetchContractsUserPermissionsSuccess = contracts => ({
  type: FETCH_CONTRACT_PERMISSIONS,
  payload: contracts,
});

export const updateContractUserPermissionSuccess = contracts => ({
  type: UPDATE_USERS_CONTRACT_PERMISSIONS,
  payload: contracts,
});

export const saveNewContract = contract => ({
  type: SAVE_NEW_CONTRACT,
  payload: contract,
});

export const updateContractUser = contract => ({
  type: UPDATE_CONTRACT_USER,
  payload: contract,
});

export const incrementNewContract = contract => ({
  type: INCREMENT_CONTRACT,
  payload: contract,
});

export const cleanContract = () => ({
  type: CLEAN_CONTRACT,
});
