import {
  INCREMENT_CONTRACT,
  FETCH_CONTRACT_PERMISSIONS,
  CLEAN_CONTRACT,
  UPDATE_USERS_CONTRACT_PERMISSIONS,
} from 'actions/ContractsPermissions';

const INITIAL_STATE = {
  contracts: [],
  permissions: [],
};

function incrementContract(state, payload) {
  if (!state.contracts.length) {
    return {
      ...state,
      contracts: [...state.contracts, payload],
    };
  }

  const arrayMap = state.contracts.filter(
    item => item.contract_id === payload.contract_id
  );

  if (!arrayMap.length) {
    const newState = state.contracts.slice();
    newState.push(payload);
    return {
      ...state,
      contracts: newState,
    };
  }

  return {
    ...state,
    contracts: state.contracts.map(item => {
      if (item.contract_id === payload.contract_id) {
        return { ...item, ...payload };
      }

      return item;
    }),
  };
}

export const contractPermission = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCREMENT_CONTRACT:
      return incrementContract(state, action.payload);

    case FETCH_CONTRACT_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
      };

    case UPDATE_USERS_CONTRACT_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
      };

    case CLEAN_CONTRACT:
      return {
        ...state,
        contracts: [],
      };

    default:
      return state;
  }
};
