import { all, fork, put, select, takeEvery, call } from 'redux-saga/effects';
import { PermissionService } from 'api/PermissionService';
import {
  SAVE_NEW_CONTRACT,
  UPDATE_CONTRACT_USER,
} from 'actions/ContractsPermissions';
import { toast } from 'react-toastify';
import {
  updateUserContract,
  updateUserUnit,
  fetchUser,
} from 'reducers/UserPermissionsDuck';

const storeValues = state => state.userPermissionsDuck.user;

const saveUserContractPermission = userContractPermission =>
  PermissionService.createContractUser(userContractPermission)
    .then(value => value.data)
    .catch(error => error.response.data);

const updateUserContractPermission = (id, userContractPermission) =>
  PermissionService.updateUserContractsPermission(id, userContractPermission)
    .then(value => value.data)
    .catch(error => error.response.data);

function* handleUserContractPermission(contractUserPermissions) {
  const request = yield call(
    saveUserContractPermission,
    contractUserPermissions
  );

  return request;
}

function* saveNewContract({ payload }) {
  const userId = payload.idPath;
  const contracts = payload.contracts;

  const contractUserPermissions = {
    contract_user: {
      user_id: userId,
      contracts_ids: contracts.map(item => ({
        contract_id: item.id,
      })),
    },
  };

  try {
    const contractUserrequest = yield call(
      handleUserContractPermission,
      contractUserPermissions
    );

    if (contractUserrequest.errors) {
      return toast.error(
        'O usuário selecionado já tem relação com esse(s) contrato(s)'
      );
    }

    toast.success('Permissões criadas com sucesso', 'success');
    yield put(fetchUser(userId));
  } catch (error) {
    return toast.error('Erro na requisição!');
  }
}

function* updateContract({ payload }) {
  const { contracts, routerId: user_id } = payload;

  const contractUserPermissions = {
    contract_user: {
      user_id,
      contracts_ids: contracts.map(item => ({
        contract_id: item.contract_id,
      })),
    },
  };

  const request = yield call(
    updateUserContractPermission,
    user_id,
    contractUserPermissions
  );

  if (!request.error) {
    const store = yield select(storeValues);
    const hashList = contracts.reduce(
      (obj, item) => ({
        ...obj,
        [item['contract_id']]: true,
      }),
      {}
    );

    const newContractUser = store.contract_users.filter(
      item => !hashList[item.contract_id] && item
    );

    const newUnits = store.unit_users.filter(
      unit => !hashList[unit.contract_id] && unit
    );

    yield put(updateUserContract(newContractUser));
    yield put(updateUserUnit(newUnits));
  }

  toast.success('Permissões atualizadas');
}

export function* saveValues() {
  yield takeEvery(SAVE_NEW_CONTRACT, saveNewContract);
}

export function* updateContractPermissionSaga() {
  yield takeEvery(UPDATE_CONTRACT_USER, updateContract);
}

export default function* rootSaga() {
  yield all([fork(updateContractPermissionSaga), fork(saveValues)]);
}
