import { format, parseISO } from 'date-fns';
import { pt as locale } from 'date-fns/locale';

const formatDate = (
  input = new Date().toLocaleString(),
  formatted = 'dd/MM/yyyy HH:mm'
) => {
  return `${new Date(input).toLocaleDateString('pt-BR')} ${new Date(input).toLocaleTimeString('pt-BR')}`;
};

export default formatDate;
