/* eslint-disable react/sort-comp */
/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Reactotron from 'reactotron-react-js';
import { withRouter, Switch } from 'react-router-dom';
import { socket } from 'api/apiSocketIo';
import { connect } from 'react-redux';
import Header from 'app/components/Header';
import Sidebar from 'containers/SideNav/index';
import Footer from 'app/components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import TopNav from 'app/components/TopNav';
import { NotificationManager } from 'react-notifications';
import {
  hideDuckNotification,
  showDuckNotification,
} from 'reducers/NotificationDuck';
import { toast } from 'react-toastify';
import AdminRoutes from 'app/routes/admin.router';
import UsersRoutes from 'app/routes/user.router';
import { receiveReport } from '../actions/SocketIO';
import '../config/ReactotronConfig';

class App extends React.Component {
  componentDidUpdate() {
    const { notificationDuck, hideDuckNotification } = this.props;
    if (notificationDuck.notification) {
      return hideDuckNotification();
    }
  }

  render() {
    const {
      showDuckNotification,
      receiveReport,
      notificationDuck,
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      userType,
    } = this.props;

    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';

    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    function reportManager(urlReport) {
      const urlArray = urlReport.split('/');
      if (urlArray[5] == 'service_order_reports') {
        return window.open(urlReport);
      }
      showDuckNotification('Seu relatório já está pronto!', 'success');
      return receiveReport(urlReport);
    }

    socket.onmessage = event => {
      const { identifier, message } = JSON.parse(event.data);
      if (identifier && message) {
        reportManager(message.report_link);
      }
    };

    const createNotification = () => {
      const { type, message } = notificationDuck;

      if (type === 'error') {
        return toast.error(`${message}`);
      }

      if (type === 'info') {
        return NotificationManager.info(message);
      }

      if (type === 'warning') {
        return toast.warning(`${message}`);
      }

      return toast.success(`${message}`);
    };

    return (
      <>
        <div className={`app-container ${drawerStyle}`}>
          <Sidebar />
          <div className="app-main-container">
            <div
              className={`app-header ${
                navigationStyle === HORIZONTAL_NAVIGATION
                  ? 'app-header-horizontal'
                  : ''
              }`}
            >
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === ABOVE_THE_HEADER && (
                  <TopNav styleName="app-top-header" />
                )}
              <Header />
              {navigationStyle === HORIZONTAL_NAVIGATION &&
                horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
            </div>
            <main className="app-main-content-wrapper">
              <div className="app-main-content">
                <div className="app-wrapper">
                  {userType === 'admin' ? (
                    <AdminRoutes match={match.url} />
                  ) : (
                    <UsersRoutes match={match.url} />
                  )}
                </div>
              </div>
              {notificationDuck.notification && createNotification()}
              <Footer />
            </main>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ settings, socketIO, auth, notificationDuck }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { notification, link } = socketIO;
  const { userType } = auth;

  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    notification,
    link,
    auth,
    userType,
    notificationDuck,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    hideDuckNotification,
    showDuckNotification,
    receiveReport,
  })(App)
);
