const SHOW_PERMISSION_ERROR = '@permissionsGeneralDucks/SHOW_PERMISSION_ERROR';
const HIDE_PERMISSION_ERROR = '@permissionsGeneralDucks/HIDE_PERMISSION_ERROR';

export function createShowPermissionError(payload) {
  return {
    type: SHOW_PERMISSION_ERROR,
    payload: {
      message: payload.message,
      type: payload.type,
    },
  };
}

export function hidePermissionError() {
  return {
    type: HIDE_PERMISSION_ERROR,
    payload: {
      message: '',
      type: '',
    },
  };
}

const initState = {
  message: '',
  type: '',
};

export const permissionsNotificationDuck = (state = initState, action) => {
  switch (action.type) {
    case SHOW_PERMISSION_ERROR:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
      };

    case HIDE_PERMISSION_ERROR:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
      };

    default:
      return state;
  }
};
