/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { history } from 'store';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    userName: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({ userName: this.props.authUser.name });
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div
        className="user-profile d-flex flex-row align-items-center"
        style={{
          padding: '10%',
        }}
      >
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {this.state.userName.split(' ')[0]}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              history.push('/app/usuarios/alterar-senha');
            }}
          >
            <i className="zmdi zmdi-key zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.changePassword" />
          </MenuItem>

          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { authUser } = auth;
  return { locale, authUser };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
