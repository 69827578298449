/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-return */

import {
  CLEAN_CUSTOMER,
  INCREMENT_CUSTOMER,
  FETCH_USERS_CUSTOMERS_PERMISSIONS,
  UPDATE_USERS_CUSTOMERS_PERMISSIONS,
  FETCH_CUSTOMERS_PERMISSIONS_CALL,
} from 'actions';

function checkIncrementCustomer(state, payload) {
  if (!state.customers.length) {
    return {
      ...state,
      customers: [...state.customers, payload],
    };
  }

  const arrayMap = state.customers.filter(
    item => item.customer_id === payload.customer_id
  );

  if (!arrayMap.length) {
    const newState = state.customers.slice();
    newState.push(payload);
    return {
      ...state,
      customers: newState,
    };
  }

  return {
    ...state,
    customers: state.customers.map(item => {
      if (item.customer_id === payload.customer_id) {
        return { ...item, ...payload };
      }

      return item;
    }),
  };
}

const INITIAL_STATE = {
  customers: [],
  permissions: [],
  request: false,
};
export const customerPermission = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCREMENT_CUSTOMER:
      return checkIncrementCustomer(state, action.payload);

    case FETCH_CUSTOMERS_PERMISSIONS_CALL:
      return {
        ...state,
        customers: state.customers,
        permissions: state.permissions,
        request: true,
      };

    case FETCH_USERS_CUSTOMERS_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
        request: false,
      };

    case UPDATE_USERS_CUSTOMERS_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
        request: false,
      };

    case CLEAN_CUSTOMER:
      return {
        ...state,
        customers: [],
        request: false,
      };

    default:
      return state;
  }
};
