// Customer
// export const SAVE_NEW_CUSTOMER = '@customer_save_new';
export const CLEAN_CUSTOMER = '@customer_clean';
export const INCREMENT_CUSTOMER = '@customer_increment_new_customer';
export const FETCH_USERS_CUSTOMERS_PERMISSIONS =
  '@fetch_users_customer_permissions';
export const UPDATE_USERS_CUSTOMERS_PERMISSIONS =
  '@update_users_customer_permissions';
export const FETCH_CUSTOMERS_PERMISSIONS_CALL =
  '@customers_fetch_user_customer_permission_call';

export const SAVE_NEW_OPERATOR = '@operator_save_new';
export const CREATE_OPERATOR = '@operator';
export const UPDATE_OPERATOR_PERMISSIONS =
  '@customers_update_operator_permissions';

export const FETCH_CUSTOMER_USERS = '@fetch_customer_users';
// export const saveNewCustomer = customer => ({
//   type: SAVE_NEW_CUSTOMER,
//   payload: customer,
// });

export const cleanCustomer = customer => ({
  type: CLEAN_CUSTOMER,
  payload: customer,
});

export const incrementNewCustomer = customer => ({
  type: INCREMENT_CUSTOMER,
  payload: customer,
});

export const fetchCustomersUsers = customers => ({
  type: FETCH_CUSTOMER_USERS,
  payload: customers,
});

export const updateCustomerUserPermissionSuccess = customers => ({
  type: UPDATE_USERS_CUSTOMERS_PERMISSIONS,
  payload: customers,
});

export const fetchCustomersPermissionsCall = () => ({
  type: FETCH_CUSTOMERS_PERMISSIONS_CALL,
});

export const saveNewOperator = operator => ({
  type: SAVE_NEW_OPERATOR,
  payload: operator,
});

export const createOperator = operator => ({
  type: CREATE_OPERATOR,
  payload: operator,
});

export const updateOperatorPermissions = permission => ({
  type: UPDATE_OPERATOR_PERMISSIONS,
  payload: permission,
});
