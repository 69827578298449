import {
  INCREMENT_UNIT,
  FETCH_UNIT_PERMISSIONS,
  UPDATE_USERS_UNIT_PERMISSIONS,
  CLEAN_UNIT,
} from 'actions/UnitsPermissions';

const INITIAL_STATE = {
  units: [],
  permissions: [],
};

function incrementUnit(state, payload) {
  if (!state.units.length) {
    return {
      ...state,
      units: [...state.units, payload],
    };
  }

  const arrayMap = state.units.filter(item => item.unit_id === payload.unit_id);

  if (!arrayMap.length) {
    const newState = state.units.slice();
    newState.push(payload);
    return {
      ...state,
      units: newState,
    };
  }

  return {
    ...state,
    units: state.units.map(item => {
      if (item.unit_id === payload.unit_id) {
        return { ...item, ...payload };
      }

      return item;
    }),
  };
}

export const unitsPermission = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCREMENT_UNIT:
      return incrementUnit(state, action.payload);

    case FETCH_UNIT_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
      };

    case UPDATE_USERS_UNIT_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
      };

    case CLEAN_UNIT:
      return {
        ...state,
        units: [],
      };

    default:
      return state;
  }
};
