import produce from 'immer';

export const types = {
  FETCH_USERS: '@users:FetchUsers',
  FETCH_USERS_SUCCESS: '@users:FetchUsersSuccess',
  FETCH_USERS_FAILED: '@users:FetchUsersFailed',

  DELETE_USERS: '@users:DeletehUsers',
  DELETE_USERS_SUCCESS: '@users:DeletehUsersSuccess',
};

export const fetchUsers = () => ({
  type: types.FETCH_USERS,
  payload: {
    loading: true,
  },
});

export const fetchUsersSuccess = users => ({
  type: types.FETCH_USERS_SUCCESS,
  payload: {
    loading: false,
    users,
  },
});

export const fetchUserFailed = () => ({
  type: types.FETCH_USERS_FAILED,
  payload: {
    payload: false,
    user_id: '',
  },
});

export const deleteUsers = () => ({
  type: types.DELETE_USERS,
  payload: {
    loading: false,
  },
});

export const deleteUsersSuccess = id => {
  return {
    type: types.DELETE_USERS_SUCCESS,
    payload: {
      loading: false,
      id,
    },
  };
};

const INITIAL_STATE = {
  users: [],
  loading: false,
  user_id: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.FETCH_USERS:
      return produce(state, draft => {
        draft.loading = payload.loading;
      });

    case types.FETCH_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading = payload.loading;
        draft.users = payload.users;
      });

    case types.FETCH_USERS_FAILED:
      return produce(state, draft => {
        draft.loading = payload.loading;
      });

    case types.DELETE_USERS:
      return produce(state, draft => {
        draft.loading = payload.loading;
      });

    case types.DELETE_USERS_SUCCESS:
      return produce(state, draft => {
        draft.loading = payload.loading;
        draft.users = state.users.filter(user => user.id !== payload.id);
        draft.user_id = payload.id;
      });

    default:
      return state;
  }
};
