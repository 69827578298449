import produce from 'immer';

const types = {
  OPEN_MODAL: '@customSettings:OpenModal',
  CLOSE_MODAL: '@customSettings:CloseModal',
};

const INITIAL_STATE = {
  openModal: false,
  userId: '',
};

export const openModal = ({ id }) => ({
  type: types.OPEN_MODAL,
  payload: {
    openModal: true,
    id,
  },
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
  payload: {
    openModal: false,
  },
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.OPEN_MODAL:
      return produce(state, draft => {
        draft.openModal = payload.openModal;
        draft.userId = payload.id;
      });

    case types.CLOSE_MODAL:
      return produce(state, draft => {
        draft.openModal = payload.openModal;
      });

    default:
      return state;
  }
};
