import { formatDate } from 'util/formats';
import { SIGNOUT_USER_SUCCESS } from 'constants/ActionTypes';
import { RECEIVE_REPORT } from '../actions/SocketIO';

const INITIAL_STATE = {
  reports: [],
};

export const socketIO = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_REPORT:
      return {
        reports: [
          ...state.reports,
          {
            link: action.payload,
            report: action.payload.split('/')[4].split('-')[0],
            date: formatDate(),
          },
        ],
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        reports: [],
      };

    default:
      return state;
  }
};
