import { toast } from 'react-toastify';
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import {
  FETCH_ALL_TICKETS,
  UPDATE_TICKET,
  fetchAllTicketsSuccess,
  closeTicketModal,
  // updateAllUnits,
  REOPEN_TICKET,
} from 'reducers/TicketDuck';
import { TicketApi } from 'api/TicketApi';

import { history } from 'store';

export const getStore = state => state.socketIO;

export function* fetchAllTickets() {
  try {
    const response = yield call(TicketApi.fetchAllTickets);
    if (response.data) {
      const { tickets } = response.data;

      return yield put(fetchAllTicketsSuccess(tickets));
    }
    toast.error('Erro ao buscar as informações de chamados');
  } catch (error) {
    return toast.error('Erro ao buscar as informações de chamados');
  }
}

// function* fetchTicket(id) {
//   try {
//     const response = yield call(TicketApi.fetchTicket, id);

//     if (response.data) {
//       const { ticket } = response.data;
//       // yield put(updateAllUnits(ticket));
//       return yield put(closeTicketModal());
//     }
//   } catch (error) {
//     return toast.error('Erro ao buscar chamado');
//   }
// }

function* updateTicketStatus({ payload }) {
  const { selected, message } = payload;
  const { id, closed_at } = selected;
  try {
    const response = yield call(TicketApi.closeTicket, id, {
      ticket: {
        status: 'closed',
        closed_at,
        ticket_notes_attributes: [
          {
            message: message === '' ? 'Fechamento de chamado' : message,
          },
        ],
      },
    });

    if (response) {
      // yield call(fetchTicket, id);
      return history.go(0);
    }
  } catch (error) {}
}

function* reopenTicketStatus({ payload }) {
  const { selected, message } = payload;
  const { id } = selected;

  try {
    const response = yield call(TicketApi.reopenTicket, id, {
      ticket: {
        status: 'open',
        ticket_notes_attributes: [
          {
            message: message === '' ? 'Reabertura de chamado' : message,
          },
        ],
      },
    });

    if (response) {
      // yield call(fetchTicket, id);
      return history.go(0);
    }
  } catch (error) {}
}

export function* fetchAllTicketsSaga() {
  yield takeLatest(FETCH_ALL_TICKETS, fetchAllTickets);
}

export function* updateTicketStatusSagas() {
  yield takeLatest(UPDATE_TICKET, updateTicketStatus);
}

export function* reopenTicketStatusSagas() {
  yield takeLatest(REOPEN_TICKET, reopenTicketStatus);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllTicketsSaga),
    fork(updateTicketStatusSagas),
    fork(reopenTicketStatusSagas),
  ]);
}
