import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, showAuthLoader, userSignIn } from 'actions/Auth';
import { showDuckNotification } from 'reducers/NotificationDuck';

import ConfirmResetEmail from 'app/pages/ConfirmResetEmail';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidUpdate() {
    const { showMessage, history, authUser } = this.props;

    if (showMessage) {
      hideMessage();
    }

    if (authUser) {
      history.push('/');
    }
  }

  render() {
    const { email, password } = this.state;
    const { loader, showAuthLoader, userSignIn } = this.props;

    const validateLogin = () => email == '' || password == '';

    return (
      <>
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-logo-content d-flex align-items-center justify-content-center">
              <div className="logo-lg" to="/" title="Jambo">
                <img
                  style={{ width: '100%' }}
                  src={require('assets/images/logo.png')}
                  alt="jambo"
                  title="jambo"
                />
              </div>
            </div>

            <div className="app-login-content">
              <div className="app-login-header mb-4">
                <h1>Bem vindo !</h1>
              </div>

              <div className="app-login-form">
                <form>
                  <fieldset>
                    <TextField
                      label={<IntlMessages id="appModule.email" />}
                      type="email"
                      fullWidth
                      onChange={event =>
                        this.setState({ email: event.target.value })
                      }
                      defaultValue={email}
                      margin="normal"
                      className="mt-1 my-sm-3"
                    />
                    <TextField
                      type="password"
                      label={<IntlMessages id="appModule.password" />}
                      fullWidth
                      onChange={event =>
                        this.setState({ password: event.target.value })
                      }
                      defaultValue={password}
                      margin="normal"
                      className="mt-1 my-sm-3"
                    />

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button
                        disabled={validateLogin()}
                        onClick={() => {
                          showAuthLoader();
                          userSignIn({ email, password });
                        }}
                        variant="contained"
                        color="primary"
                      >
                        <span>Entrar</span>
                      </Button>

                      <ConfirmResetEmail />
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>

          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, user } = auth;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    user,
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  showDuckNotification,
})(SignIn);
