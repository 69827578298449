/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import { Button } from '@material-ui/core';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains('open'))
            ) {
              menuLi[j].classList.remove('open');
            } else if (menuLi[j].classList.contains('open')) {
              menuLi[j].classList.remove('open');
            } else {
              menuLi[j].classList.add('open');
            }
          }
        }
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some(fn => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return null;
  }

  renderMenuOfAccordingOfUserType = () => {
    if(this.props.userType === 'operator') {
      return <SideMenuOperator />
    } 
    else if (this.props.userType === 'admin') {
      return <SideMenuAdmin />
    } 
    else if (this.props.userType === 'customer') {
      return <SideMenu />
    }
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        {this.renderMenuOfAccordingOfUserType()}
      </CustomScrollbars>
    );
  }
}

const SideMenuOperator = () => {
  return (
    <ul className="nav-menu">
      <li className="nav-header"></li>

      <li className="menu no-arrow">
        <NavLink to="/app/clientes">
          <i className="zmdi zmdi-accounts zmdi-hc-fw" />
          <span className="nav-text">Clientes</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/chamados">
          <i className="zmdi zmdi-file-plus zmdi-hc-fw" />
          <span className="nav-text">Chamados</span>
        </NavLink>
      </li>
  </ul>
  )
}

const SideMenu = () => {
  return (
    <ul className="nav-menu">
      <li className="nav-header"></li>

      <li className="menu no-arrow">
        <NavLink to="/app/clientes">
          <i className="zmdi zmdi-accounts zmdi-hc-fw" />
          <span className="nav-text">Clientes</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/chamados">
          <i className="zmdi zmdi-file-plus zmdi-hc-fw" />
          <span className="nav-text">Chamados</span>
        </NavLink>
      </li>
    </ul>
  );
};

const SideMenuAdmin = () => {
  return (
    <ul className="nav-menu">
      <li className="nav-header"></li>

      <li className="menu no-arrow">
        <NavLink to="/app/clientes">
          <i className="zmdi zmdi-accounts zmdi-hc-fw" />
          <span className="nav-text">Clientes</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink className="prepend-icon" to="/app/usuarios">
          <i className="zmdi zmdi-account-calendar zmdi-hc-fw" />
          <span className="nav-text">Usuários</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink className="prepend-icon" to="/app/permissoes">
          <i className="zmdi zmdi-lock zmdi-hc-fw" />
          <span className="nav-text">Permissões</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/terceirizadas">
          <i className="zmdi zmdi-puzzle-piece zmdi-hc-fw" />
          <span className="nav-text">Terceirizadas</span>
        </NavLink>
      </li>

      <li className="menu no-arrow">
        <NavLink to="/app/chamados">
          <i className="zmdi zmdi-file-plus zmdi-hc-fw" />
          <span className="nav-text">Chamados</span>
        </NavLink>
      </li>

      <li className="menu">
        <Button>
          <i className="zmdi zmdi-attachment-alt zmdi-hc-fw" />
          <span className="nav-text">Relatórios</span>
        </Button>

        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon" to="/app/relatorios">
              <span className="nav-text">Chamados</span>
            </NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userType } = auth;

  return {
    userType,
  };
};

export default withRouter(connect(mapStateToProps)(SidenavContent));
