import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';

export default function UsersRoutes({ match }) {
  return (
    <Switch>
      <Route
        path={`${match}/usuarios/alterar-senha`}
        exact
        component={asyncComponent(() => import('../pages/UsersChangePassword'))}
      />
      <Route
        exact
        path={`${match}/clientes`}
        component={asyncComponent(() => import('../pages/CustomerPage'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos`}
        component={asyncComponent(() => import('../pages/ContractsList'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/unidades`}
        component={asyncComponent(() => import('../pages/Units/index'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/unidades/:id/chamados`}
        component={asyncComponent(() => import('../pages/UnitsPage/UnitsList'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/unidades/:id/chamados/criar`}
        component={asyncComponent(() => import('../pages/Ticket'))}
      />
      <Route
        exact
        path={`${match}/chamados`}
        component={asyncComponent(() => import('../pages/TicketList'))}
      />
      <Route
        exact
        path={`${match}/chamados/criar`}
        component={asyncComponent(() => import('../pages/Ticket'))}
      />
      <Route
        exact
        path={`${match}/chamados/:id/ordem/criar`}
        component={asyncComponent(() =>
          import('../pages/CreateServiceOrder/CreateServiceOrder')
        )}
      />
      <Route
        exact
        path={`${match}/chamados/:id/ordem`}
        component={asyncComponent(() => import('../pages/ServiceOrderList'))}
      />
      <Route
        path={`${match}/chamados/:id/ordem/:id/detalhes`}
        component={asyncComponent(() => import('../pages/ServiceOrderDetails'))}
      />
      <Route
        path={`${match}/chamados/:id/ordem/:id/editar`}
        component={asyncComponent(() => import('../pages/ServiceOrderEdit'))}
      />
      <Route
        path={`${match}/chamados/:id/editar`}
        component={asyncComponent(() => import('../pages/TicketEdit'))}
      />
      <Route
        path={`${match}/chamados/:id/informacoes`}
        component={asyncComponent(() => import('../pages/TicketInfo'))}
      />
      <Route
        component={asyncComponent(() => import('app/components/Error404'))}
      />
    </Switch>
  );
}
