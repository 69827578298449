import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import {
  FETCH_USER,
  fetchUserDetailsSuccess,
  // fetchUserDetailsFail,
} from 'reducers/UserPermissionsDuck';
import { UserService } from 'api/UserService';

function* fetchUser({ payload }) {
  try {
    const response = yield call(UserService.fetchUser, payload);

    if (response.data) {
      yield put(fetchUserDetailsSuccess(response.data.user));
    }
  } catch (error) {
    yield put(error);
  }
}

export function* fetchUserPermissionDetails() {
  yield takeEvery(FETCH_USER, fetchUser);
}

export default function* rootSaga() {
  yield all([fork(fetchUserPermissionDetails)]);
}
