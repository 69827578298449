import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import formSagas from './Form';
import permissionSaga from './Permissions';
import contractPermissionSaga from './PermitionContract';
import unitPermissionSaga from './PermitionUnit';
import socketNotification from './SocketNotification';
import userDeitals from './UserDetails';
import ticketSagas from './TicketSagas';
import userSagas from './userSagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    formSagas(),
    permissionSaga(),
    contractPermissionSaga(),
    unitPermissionSaga(),
    socketNotification(),
    userDeitals(),
    ticketSagas(),
    userSagas(),
  ]);
}
