import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Form from './FormCreate';
import { customerPermission } from './Permissions';
import { contractPermission } from './PermissionContract';
import { unitsPermission } from './PermissionUnit';
import { socketIO } from './SocketIO';
import { notificationDuck } from './NotificationDuck';
import { permissionsNotificationDuck } from './PermissionsNotificationDuck';
import { unitsPermissionDuck } from './UnitsPermissionDuck';
import { userPermissionsDuck } from './UserPermissionsDuck';
import ticketDuck from './TicketDuck';
import customSettings from './CustomSettings';
import users from './UserDuck';

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    formErrors: Form,
    customerPermission,
    contractPermission,
    unitsPermission,
    socketIO,
    notificationDuck,
    permissionsNotificationDuck,
    unitsPermissionDuck,
    userPermissionsDuck,
    ticketDuck,
    customSettings,
    users,
  });
