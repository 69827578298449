import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
});

api.interceptors.request.use(
  config => {
    config.headers.common = JSON.parse(sessionStorage.getItem('user'));
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 404) {
      // TODO: fix this
      // window.location.replace(`${window.location.origin}/app/404`);
    }
    return Promise.reject(error);
  }
);
