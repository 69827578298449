export const ADD_NEW_UNIT_PERMISSION = '@unitPermission_add_unit_permission';
export const CLEAN_UNIT_PERMISSION = '@unitPermission_clean_unit_permission';
export const REMOVE_UNIT_PERMISSION = '@unitPermission_remove_unit_permission';
export const UPDATE_USERS_UNIT_PERMISSIONS ='@unitPermission_update_user_unit_permission';
export const UPDATE_UNIT_PERMISSION = '@unitPermission_update_unit_permission';

export const addNewUnitPermission = unitPermission => {
  return {
    type: ADD_NEW_UNIT_PERMISSION,
    payload: {
      unitPermission,
    },
  };
};

export const cleanUnitPermissionStore = () => ({
  type: CLEAN_UNIT_PERMISSION,
});

export const updateUnitUserPermission = permission => {
  return {
    type: UPDATE_USERS_UNIT_PERMISSIONS,
    payload: permission,
  };
};

export const removeUnitPermission = permission => ({
  type: REMOVE_UNIT_PERMISSION,
  payload: permission,
});

export const updateUnitPermission = permission => ({
  type: UPDATE_UNIT_PERMISSION,
  payload: permission,
});

const INITIAL_STATE = {
  permissions: [],
};

function incrementUnit(state, { unitPermission }) {
  if (!state.permissions.length) {
    return {
      ...state,
      permissions: [...state.permissions, unitPermission],
    };
  }

  const arrayMap = state.permissions.filter(
    item => item.id === unitPermission.id
  );

  if (!arrayMap.length) {
    const newState = state.permissions.slice();
    newState.push(unitPermission);
    return {
      ...state,
      permissions: newState,
    };
  }

  return {
    ...state,
    permissions: state.permissions.map(item => {
      if (item.id === unitPermission.id) {
        return { ...item, ...unitPermission };
      }

      return item;
    }),
  };
}

export const unitsPermissionDuck = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAN_UNIT_PERMISSION:
      return {
        ...state,
        permissions: [],
      };
    case ADD_NEW_UNIT_PERMISSION:
      return incrementUnit(state, action.payload);

    case REMOVE_UNIT_PERMISSION: {
      return {
        ...state,
        permissions: [...action.payload],
      };
    }
    case UPDATE_USERS_UNIT_PERMISSIONS:
      return {
        ...state,
        permissions: [...action.payload],
      };

    default:
      return state;
  }
};
