import {
  SHOW_FORM_ERROR,
  HIDDEN_FORM_ERROR,
  SHOW_SUCCESS_MESSAGE_FORM,
  HIDDEN_SUCCESS_MESSAGE_FORM,
} from '../actions/FormCreate';

const INITIAL_STATE = {
  visible: false,
  visibleSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HIDDEN_FORM_ERROR:
      return {
        ...state,
        visible: false,
      };
    case SHOW_FORM_ERROR:
      return {
        ...state,
        visible: true,
      };
    case SHOW_SUCCESS_MESSAGE_FORM:
      return {
        ...state,
        visibleSuccess: true,
      };
    case HIDDEN_SUCCESS_MESSAGE_FORM:
      return {
        ...state,
        visibleSuccess: false,
      };
    default:
      return state;
  }
};
