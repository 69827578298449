import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from 'constants/ActionTypes';

export const userSignUp = user => ({
  type: SIGNUP_USER,
  payload: user,
});
export const userSignIn = user => ({
  type: SIGNIN_USER,
  payload: user,
});
export const userSignOut = () => ({
  type: SIGNOUT_USER,
});
export const userSignUpSuccess = authUser => ({
  type: SIGNUP_USER_SUCCESS,
  payload: authUser,
});

export const userSignInSuccess = (authUser, userType, url) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: {
      authUser: authUser.userinfo,
      userType,
      url,
    },
  };
};
export const userSignOutSuccess = () => ({
  type: SIGNOUT_USER_SUCCESS,
});

export const showAuthMessage = message => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const setInitUrl = url => ({
  type: INIT_URL,
  payload: url,
});

export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});

export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER,
});
