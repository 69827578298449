import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { UserService } from 'api/UserService';

export default function ConfirmResetEmail() {
  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!open) {
      setEmail('');
    }
  }, [open]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleResetPassword() {
    if (email.trim() == '') {
      return toast.warn('Informe um email para continuar');
    }

    const request = await UserService.resetPassword({
      redirect_url: `${window.location.origin}/redefinir`,
      email,
    })
      .then(response => response)
      .catch(error => error);

    if (request.data) {
      toast.success(request.data.message);
      return handleClose();
    }
    const { errors } = request.response.data;
    return errors.map(error => toast.error(error));
  }

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{ textTransform: 'none' }}
      >
        Esqueceu sua senha?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Recuperar Senha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, informe o email cadastrado.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleResetPassword} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
