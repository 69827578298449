import { api } from './api';

export class UserService {
  static fetchAllUsers = () => api.get('users');

  static fetchUser = id => api.get(`users/${id}`);

  static createUser = user => {
    return api.post('users', user);
  };

  static updateUser = (id, user) => api.put(`users/${id}`, user);

  static deleteUser = async id => {
    await api.delete(`users/${id}`);
    document.location.reload(true);
  };

  static changePassword = payload => {
    return api.patch('users/update_password', { user: payload });
  };

  static resetPassword = resetObject => api.post(`/auth/password`, resetObject);

  static fetchUserTickets = async user_id =>
    api.get(`users/${user_id}/tickets`);

  static ticketsTransferTo = async ({ id, new_user_id }) =>
    api.put(`users/${id}/tickets`, { new_user_id });
}
