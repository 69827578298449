import { applyMiddleware, compose, createStore } from 'redux';
import Reactotron from 'reactotron-react-js';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import reducers from '../reducers/index';
import rootSaga from '../sagas/index';
import '../config/ReactotronConfig';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMonitor = Reactotron.createSagaMonitor();
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const middlewares = [sagaMiddleware, routeMiddleware];

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(Reactotron.createEnhancer(), applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

const persistConfig = {
  key: 'ativaManager',
  storage,
  whitelist: ['auth', 'socketIO'],
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  const persistor = persistStore(store);

  return { store, persistor };
}
export { history };
