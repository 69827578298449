export const RECEIVE_REPORT = '@report/RECEIVE_REPORT';
export const CLEAN_REPORT_NOTIFICATION = '@report/CLEAN_REPORT_NOTIFICATION';

export const receiveReport = link => {
  return {
    type: RECEIVE_REPORT,
    payload: link,
  };
};

export const cleanReportNotification = () => ({
  type: CLEAN_REPORT_NOTIFICATION,
});
