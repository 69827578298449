/* eslint-disable require-yield */
import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  select,
  call,
} from 'redux-saga/effects';

import { showDuckNotification } from 'reducers/NotificationDuck';
import { toast } from 'react-toastify';
import { SAVE_NEW_UNIT } from 'actions/UnitsPermissions';
import { UPDATE_UNIT_PERMISSION } from 'reducers/UnitsPermissionDuck';
import { PermissionService } from 'api/PermissionService';
// import { history } from 'store';

const getStorePermission = state => state.unitsPermissionDuck.permissions;

const saveUserUnitsPermissions = obj =>
  PermissionService.createUnitUser(obj)
    .then(value => value.data)
    .catch(error => error.response.data);

function* handleUserUnitPermission(unitUserPermissions) {
  const request = yield call(saveUserUnitsPermissions, unitUserPermissions);

  return request;
}

const fetchUnitsRequest = () => {
  return PermissionService.fetchUnitUsers();
};

const updatePermissions = (id, obj) => {
  return PermissionService.updateUserUnitPermission(id, obj)
    .then(value => value.data)
    .catch(error => error.response.data);
};

function* saveNewUnit({ payload }) {
  const store = yield select(getStorePermission);

  const userId = payload.idPath;
  const { units } = payload;
  const { setAll } = payload;

  const hashList = store.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: {
        unit_id: item.id,
        can_create_ticket: item.can_create_ticket
          ? item.can_create_ticket
          : false,
        can_update_ticket: item.can_update_ticket
          ? item.can_update_ticket
          : false,
        can_reopen_ticket: item.can_reopen_ticket
          ? item.can_reopen_ticket
          : false,
        can_close_ticket: item.can_close_ticket ? item.can_close_ticket : false,
      },
    };
  }, {});

  const unitUserPermissions = {
    unit_user: {
      user_id: userId,
      units_ids: setAll
        ? units.map(item => {
            return {
              unit_id: item.id,
              can_create_ticket: store[0].can_create_ticket,
              can_update_ticket: store[0].can_update_ticket,
              can_reopen_ticket: store[0].can_reopen_ticket,
              can_close_ticket: store[0].can_close_ticket,
            };
          })
        : units.map(item => hashList[item.id]),
    },
  };

  try {
    const unitUserrequest = yield call(
      handleUserUnitPermission,
      unitUserPermissions
    );

    if (unitUserrequest.errors) {
      return toast.error(
        'O usuário selecionado já tem relação com essa(s) unidade(s)'
      );
    }
    return yield put(
      showDuckNotification('Permissões criadas com sucesso', 'success')
    );
  } catch (error) {
    yield put(showDuckNotification('Erro na requisição', 'error'));
  }
}

function* updateUnit({ payload }) {
  const store = yield select(getStorePermission);

  const { userId } = payload;
  const { units } = payload;
  const { setAll } = payload;

  const hashList = store.reduce((obj, item) => {
    return {
      ...obj,
      [item.id]: {
        unit_id: item.unit_id,
        unit_users_permissions: [
          {
            can_create_ticket: item.can_create_ticket,
          },
          {
            can_update_ticket: item.can_update_ticket,
          },
          {
            can_reopen_ticket: item.can_reopen_ticket,
          },
          {
            can_close_ticket: item.can_close_ticket,
          },
        ],
      },
    };
  }, {});

  const objectToUpdate = {
    unit_user: {
      user_id: userId,
      unit_users_attributes: setAll
        ? units.map(item => {
            return {
              unit_id: item.id,
              unit_users_permissions: [
                { can_create_ticket: store[0].can_create_ticket },
                { can_update_ticket: store[0].can_update_ticket },
                { can_reopen_ticket: store[0].can_reopen_ticket },
                { can_close_ticket: store[0].can_close_ticket },
              ],
            };
          })
        : units.map(item => hashList[item.id]),
    },
  };

  try {
    const request = yield call(
      updatePermissions,
      payload.userId,
      objectToUpdate
    );

    if (request.errors) {
      return toast.error(request.errors.details);
    }
    toast.success('Permissões atualizadas');
  } catch (error) {
    toast.error('Erro interno do sistema, contacte o suporte');
  }
}

export function* saveValues() {
  yield takeEvery(SAVE_NEW_UNIT, saveNewUnit);
}

export function* updateUserUnitPermissions() {
  yield takeLatest(UPDATE_UNIT_PERMISSION, updateUnit);
}

export default function* rootSaga() {
  yield all([fork(saveValues), fork(updateUserUnitPermissions)]);
}
