import { all, fork, put, takeLatest, call, select } from 'redux-saga/effects';
import { RECEIVE_REPORT } from 'actions/SocketIO';

export const getStore = state => state.socketIO;

function* saveLocalStorage(payload) {
  const state = yield select(getStore);
  if (state.reports) {
    localStorage.setItem('reports', JSON.stringify(state.reports));
  }
}

export function* fetchReportSaga() {
  yield takeLatest(RECEIVE_REPORT, saveLocalStorage);
}

export default function* rootSaga() {
  yield all([fork(fetchReportSaga)]);
}
