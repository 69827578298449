const CREATED_NOTIFICATION = '@duckNotification-created-showNotification';
const SHOW_NOTIFICATION = '@duckNotification-showNotification';
const HIDE_NOTIFICATION = '@duckNotification-hideNotification';

export const createdDuckNotification = (type, message) => {
  return {
    type: CREATED_NOTIFICATION,
    payload: {
      message,
      type,
    },
  };
};

export const showDuckNotification = (type, message) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      type,
      message,
    },
  };
};

export const hideDuckNotification = () => ({
  type: HIDE_NOTIFICATION,
});

const initialState = {
  notification: false,
  type: '',
  message: '',
};

export const notificationDuck = (state = initialState, action) => {
  switch (action.type) {
    case CREATED_NOTIFICATION:
      return {
        ...state,
        notification: true,
        type: action.payload.type,
        message: action.payload.message,
      };

    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: true,
        type: action.payload.type,
        message: action.payload.message,
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: false,
        type: '',
        message: '',
      };

    default:
      return state;
  }
};
