import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';

export default function AdminRoutes({ match }) {
  return (
    <Switch>
      <Route
        path={`${match}/usuarios/alterar-senha`}
        exact
        component={asyncComponent(() => import('../pages/UsersChangePassword'))}
      />
      <Route
        exact
        path={`${match}/clientes`}
        component={asyncComponent(() => import('../pages/CustomerPage'))}
      />
      <Route
        path={`${match}/clientes/criar`}
        component={asyncComponent(() => import('../pages/CreateCustomer'))}
      />
      <Route
        path={`${match}/clientes/:id/contratos/criar`}
        exact
        component={asyncComponent(() => import('../pages/CreateContract'))}
      />
      <Route
        path={`${match}/clientes/:id/contratos/:id/unidades/criar`}
        component={asyncComponent(() => import('../pages/UnitsPage/FormUnits'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id`}
        component={asyncComponent(() => import('../pages/ContractsList'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/editar`}
        component={asyncComponent(() => import('../pages/ContractEdit'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/unidades`}
        component={asyncComponent(() => import('../pages/Units'))}
      />
      <Route
        exact
        path={`${match}/clientes/:id/contratos/:id/unidades/:id/chamados`}
        component={asyncComponent(() => import('../pages/UnitsPage/UnitsList'))}
      />
      <Route
        path={`${match}/clientes/:id/contratos/:id/unidades/:id/editar`}
        component={asyncComponent(() => import('../pages/UnitsPage/EditUnit'))}
      />
      <Route
        path={`${match}/clientes/:id/contratos/:id/unidades/:id/informacoes`}
        component={asyncComponent(() => import('../pages/UnitInformations'))}
      />
      <Route
        path={`${match}/clientes/:id/editar`}
        component={asyncComponent(() => import('../pages/EditCustomer'))}
      />
      <Route
        path={`${match}/usuarios`}
        exact
        component={asyncComponent(() => import('../pages/Users'))}
      />
      <Route
        path={`${match}/usuarios/criar`}
        component={asyncComponent(() => import('../pages/CreateUser'))}
      />
      <Route
        path={`${match}/usuarios/:id`}
        exact
        component={asyncComponent(() => import('../pages/Users/Details'))}
      />
      <Route
        path={`${match}/usuarios/:id/editar`}
        component={asyncComponent(() => import('../pages/Users/Edit'))}
      />
      <Route
        path={`${match}/permissoes`}
        exact
        component={asyncComponent(() => import('../pages/Permissions'))}
      />
      <Route
        exact
        path={`${match}/permissoes/:id`}
        component={asyncComponent(() =>
          import('../pages/UserPermissions/PermissionForm')
        )}
      />
      <Route
        path={`${match}/permissoes/:id/editar`}
        component={asyncComponent(() =>
          import('../pages/UserPermissions/PermissionForm')
        )}
      />
      <Route
        exact
        path={`${match}/terceirizadas`}
        component={asyncComponent(() => import('../pages/Outsourceds/index'))}
      />
      <Route
        path={`${match}/terceirizadas/criar`}
        component={asyncComponent(() =>
          import('../pages/Outsourceds/CreateOutsourced')
        )}
      />
      <Route
        path={`${match}/terceirizadas/:id/editar`}
        component={asyncComponent(() =>
          import('../pages/Outsourceds/EditOutsourced')
        )}
      />
      <Route
        path={`${match}/terceirizadas/:id/info`}
        component={asyncComponent(() => import('../pages/OutsourcedDetails'))}
      />
      <Route
        exact
        path={`${match}/chamados`}
        component={asyncComponent(() => import('../pages/TicketList'))}
      />
      <Route
        exact
        path={`${match}/chamados/criar`}
        component={asyncComponent(() => import('../pages/Ticket'))}
      />
      <Route
        path={`${match}/chamados/:id/ordem/criar`}
        component={asyncComponent(() =>
          import('../pages/CreateServiceOrder/CreateServiceOrder')
        )}
      />
      <Route
        exact
        path={`${match}/chamados/:id/ordem`}
        component={asyncComponent(() => import('../pages/ServiceOrderList'))}
      />
      <Route
        path={`${match}/chamados/:id/ordem/:id/detalhes`}
        component={asyncComponent(() => import('../pages/ServiceOrderDetails'))}
      />
      <Route
        path={`${match}/chamados/:id/ordem/:id/editar`}
        component={asyncComponent(() => import('../pages/ServiceOrderEdit'))}
      />
      <Route
        path={`${match}/chamados/:id/editar`}
        component={asyncComponent(() => import('../pages/TicketEdit'))}
      />
      <Route
        exact
        path={`${match}/chamados/:id/informacoes`}
        component={asyncComponent(() => import('../pages/TicketInfo'))}
      />
      <Route
        exact
        path={`${match}/relatorios`}
        component={asyncComponent(() => import('../pages/Reports'))}
      />
      <Route
        path={`${match}/relatorios/criar`}
        component={asyncComponent(() =>
          import('../pages/CreateTicketReports/index')
        )}
      />
      <Route
        path={`${match}/usuarios/transferencia/:id`}
        component={asyncComponent(() => import('../pages/Users/Edit'))}
      />
      <Route
        component={asyncComponent(() => import('app/components/Error404'))}
      />
    </Switch>
  );
}
