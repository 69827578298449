/* eslint-disable no-param-reassign */
import produce from 'immer';

export const FETCH_ALL_TICKETS = '@tickets_FETCH_ALL_TICKETS';
export const FETCH_ALL_TICKETS_SUCCESS = '@tickets_FETCH_ALL_TICKETS_SUCCESS';
export const FETCH_ALL_TICKETS_FAIL = '@tickets_FETCH_ALL_TICKETS_FAIL';

export const UPDATE_TICKET = '@tickets_UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = '@tickets_UPDATE_TICKET_SUCCESS';

export const REOPEN_TICKET = '@tickets_REOPEN_TICKET';
export const REOPEN_TICKET_SUCCESS = '@tickets_REOPEN_TICKET_SUCCESS';

export const OPEN_TICKET_MODAL = '@tickets_OPEN_TICKET_MODAL';
export const CLOSE_TICKET_MODAL = '@tickets_CLOSE_TICKET_MODAL';

export const UPDATE_TICKETS_LIST = '@tickets_UPDATE_TICKETS_LIST';

export const fetchAllTickets = () => ({
  type: FETCH_ALL_TICKETS,
});

export const fetchAllTicketsSuccess = tickets => ({
  type: FETCH_ALL_TICKETS_SUCCESS,
  payload: tickets,
});

export const fetchAllTicketsFail = () => ({
  type: FETCH_ALL_TICKETS_FAIL,
});

export const updateTicket = (selected, message) => ({
  type: UPDATE_TICKET,
  payload: {
    selected,
    message,
  },
});

export const updateTicketSuccess = ticket => {
  return {
    type: UPDATE_TICKET_SUCCESS,
    payload: {
      ticket,
    },
  };
};

export const reopenTicket = (selected, message) => ({
  type: REOPEN_TICKET,
  payload: {
    selected,
    message,
  },
});

export const reopenTicketSuccess = ticket => ({
  type: REOPEN_TICKET_SUCCESS,
  payload: {
    ticket,
  },
});

export const openTicketModal = () => ({
  type: OPEN_TICKET_MODAL,
});

export const closeTicketModal = () => ({
  type: CLOSE_TICKET_MODAL,
});


const INITIAL_STATE = {
  tickets: [],
  loading: true,
  submiting: false,
  openModal: false,
  selectedTicket: {},
};

const ticketDuck = (state = INITIAL_STATE, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case OPEN_TICKET_MODAL: {
        draft.openModal = true;
        break;
      }

      case CLOSE_TICKET_MODAL: {
        draft.openModal = false;
        break;
      }
      case FETCH_ALL_TICKETS: {
        draft.loading = true;
        break;
      }

      case FETCH_ALL_TICKETS_SUCCESS: {
        draft.tickets = payload;
        draft.loading = false;
        break;
      }

      case FETCH_ALL_TICKETS_FAIL: {
        draft.loading = false;
        break;
      }

      case UPDATE_TICKET: {
        draft.loading = true;
        draft.submiting = true;
        break;
      }

      case UPDATE_TICKETS_LIST: {
        draft.tickets = state.tickets.map(item => {
          if (item.id === payload.ticket.id) {
            return payload.ticket;
          }
          return item;
        });
        draft.selectedTicket = payload.ticket;
        draft.loading = false;
        break;
      }

      case UPDATE_TICKET_SUCCESS: {
        draft.loading = false;
        draft.submiting = false;
        break;
      }
      default:
    }
  });
};

export default ticketDuck;
