import {
  all,
  fork,
  takeEvery,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { UserService } from 'api/UserService';
import { toast } from 'react-toastify';
import {
  types,
  fetchUsersSuccess,
  fetchUserFailed,
  deleteUsersSuccess,
} from '../reducers/UserDuck';
import { closeModal } from '../reducers/CustomSettings';

const customSettingsRedux = state => state.customSettings;

const { FETCH_USERS, DELETE_USERS } = types;

export const userStore = state => state.users;

async function deleteUser(id) {
  const response = await UserService.deleteUser(id)
    .then(value => value)
    .catch(error => error);

  return response;
}

const fetchUsers = async () =>
  UserService.fetchAllUsers()
    .then(value => value)
    .catch(error => error);

function* fetchUsersAction() {
  try {
    const response = yield call(fetchUsers);
    if (response.data) {
      const { users } = response.data;
      yield put(closeModal());
      return yield put(fetchUsersSuccess(users));
    }
  } catch (error) {
    toast.error('Erro ao buscar usuários');
    yield put(closeModal());
    return yield put(fetchUserFailed());
  }
}

function* deleteUsersAction() {
  try {
    const customSettings = yield select(customSettingsRedux);
    const response = yield call(deleteUser, customSettings.userId);
    if (!response) {
      yield put(closeModal());
      return yield put(deleteUsersSuccess(customSettings.userId));
    }
    yield put(closeModal());
    return toast.error('ERRO AO DELTAR O USUÁRIO 1');
  } catch (error) {
    yield put(closeModal());
    return toast.error('ERRO DE SISTEMA');
  }
}

export function* fetchUsersSagas() {
  yield takeEvery(FETCH_USERS, fetchUsersAction);
}

export function* deleteUserSagas() {
  yield takeLatest(DELETE_USERS, deleteUsersAction);
}

export default function* rootSaga() {
  yield all([fork(fetchUsersSagas), fork(deleteUserSagas)]);
}
