export const FETCH_UNIT_PERMISSIONS = '@unit_fetch_unit';
export const INCREMENT_UNIT = '@unit_increment_unit';
export const UPDATE_USERS_UNIT_PERMISSIONS = '@unit_update_unit';
export const UPDATE_UNIT_PERMISSION = '@unit_update_permission';
export const CLEAN_UNIT = '@unit_clean_unit';
export const SAVE_NEW_UNIT = '@unit_save_new_user_unit';
export const FETCH_USERS_UNITS_PERMISSIONS_CALL = '@unit_fetch_unit_call';

export const updateUnitPermission = units => ({
  type: UPDATE_UNIT_PERMISSION,
  payload: units,
});

export const fetchUnitsUserPermissionsSuccess = units => ({
  type: FETCH_UNIT_PERMISSIONS,
  payload: units,
});

export const fetchUnitsUserPermissionsCall = () => ({
  type: FETCH_USERS_UNITS_PERMISSIONS_CALL,
});

export const updateUnitUserPermissionSuccess = units => ({
  type: UPDATE_USERS_UNIT_PERMISSIONS,
  payload: units,
});

export const saveNewUnit = unit => ({
  type: SAVE_NEW_UNIT,
  payload: unit,
});

export const incrementNewUnit = unit => ({
  type: INCREMENT_UNIT,
  payload: unit,
});

export const cleanUnit = () => ({
  type: CLEAN_UNIT,
});
