/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import CardBox from 'app/components/CardBox';
import { api } from 'api/api';
import { toast } from 'react-toastify';
import Axios from 'axios';

export default function ResetPassword({ history }) {
  const urlHeaders = history.location.search;

  const [resetObject, setResetObject] = useState({
    password: '',
    password_confirmation: '',
  });

  function handleInputChange(event) {
    setResetObject({
      ...resetObject,
      [event.target.name]: event.target.value,
    });
  }

  function handleResetPassword() {
    api
      .put(`/auth/password${urlHeaders}`, {
        password: resetObject.password,
        password_confirmation: resetObject.password_confirmation,
      })
      .then(value => {
        history.push('/');
        toast.success('Senha redefinida com sucesso');
      })
      .catch(error => toast.error('Ops! Acorreu um erro ao redefinir a senha'));
  }

  const disableResetButton = () =>
    resetObject.password === '' ||
    resetObject.password_confirmation === '' ||
    resetObject.password !== resetObject.password_confirmation;

  return (
    <div className="app-wrapper">
      <div className="col-md-8 offset-md-2">
        <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0">Ativa System</h2>
          <nav className="mb-0">
            <span className="title mb-3 mb-sm-0 active breadcrumb-item">
              Redefinir Senha
            </span>
          </nav>
        </div>
      </div>

      <CardBox
        styleName="col-md-8 offset-md-2"
        children={
          <>
            <form noValidate autoComplete="off">
              <TextField
                margin="normal"
                fullWidth
                id="new-password"
                label="Nova senha"
                type="password"
                color="primary"
                value={resetObject.password}
                name="password"
                onChange={e => handleInputChange(e)}
              />
              <TextField
                margin="normal"
                fullWidth
                id="confirm-new-password"
                label="Confirmar nova senha"
                type="password"
                color="primary"
                value={resetObject.password_confirmation}
                name="password_confirmation"
                onChange={e => handleInputChange(e)}
              />
            </form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: '20px',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                size="normal"
                disabled={disableResetButton()}
                onClick={handleResetPassword}
              >
                Confirmar
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
}
