import {
  all,
  fork,
  put,
  takeLatest,
  takeEvery,
  call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { history } from 'store';
import { showDuckNotification } from 'reducers/NotificationDuck';
import { createShowPermissionError } from 'reducers/PermissionsNotificationDuck';
import {
  cleanCustomer,
  fetchCustomersUsers,
  SAVE_NEW_OPERATOR,
  FETCH_CUSTOMERS_PERMISSIONS_CALL,
  UPDATE_OPERATOR_PERMISSIONS,
} from 'actions/Permissions';
import { cleanContract } from 'actions/ContractsPermissions';
import { cleanUnit } from 'actions/UnitsPermissions';
import { PermissionService } from 'api/PermissionService';

/* Aqui vai salvar a relação do Usuário Cliente com os Clientes selecionados na Tela de Permissões (acho que é isso). */
const createCustomerUsers = customerUsers =>
  PermissionService.createCustomerUsers(customerUsers)
    .then(value => value.data)
    .catch(error => error.response.data);

const fetchCustomerUsers = () =>
  PermissionService.fetchCustomerUsers().then(value => value.data);

const updateCustomerUserOperator = (id, obj) => {
  return PermissionService.updateUserCustomersPermission(id, obj)
    .then(value => value.data)
    .catch(error => error.response);
};

function* fetchCustomerPermission() {
  try {
    const requestCustomers = yield call(fetchCustomerUsers);
    if (requestCustomers) {
      const { customer_users: customersPermission } = requestCustomers;
      return yield put(fetchCustomersUsers(customersPermission));
    }
  } catch (error) {
    // eslint-disable-next-line no-alert
    return alert('Erro ao buscar as permissões dos clientes');
  }
}

function* handleUserCustomerPermissions(customerUserPermission) {
  const request = yield call(createCustomerUsers, customerUserPermission);

  if (request.errors) return request;

  return request;
}

/*
function* handleCustomerUserPermissions(customerUserPermission) {
  const request = yield call(
    saveUserCustomerPermission,
    customerUserPermission
  );

  if (request.errors) return request;

  return request;
}
*/

// Usuário Operador é associado à clientes através de customer_users (user_id*, customer_id*) e retorna user_id, customer_id e customer_name. A "permissão" de usuários Operadores é definida por esta relação.
// Uma vez definidos os clientes que o Operador tem relação, ele terá todas as permissões de todos os contratos e unidades deste cliente.

function* saveNewOperator({ payload }) {
  const userId = payload.routerPath.split('/')[3];
  const customers = payload.selectedCustomer;

  const customerUserPermission = {
    customer_user: {
      user_id: userId,
      customers_ids: customers.map(item => ({
        customer_id: item.id,
      })),
    },
  };

  try {
    const createRequest = yield call(
      handleUserCustomerPermissions,
      customerUserPermission // user_id*, customer_id*
    );

    if (createRequest.errors) {
      return toast.error(
        'O usuário selecionado já tem relação com esse(s) clientes(s)'
      );
    }

    return yield put(
      showDuckNotification('Permissões criadas com sucesso', 'success')
    );
  } catch (error) {
    return toast.error(
      'O usuário selecionado já tem relação com esse(s) clientes(s)'
    );
  }
}

/*

function* createOperator({ payload }) {
  const userId = payload.routerPath.split('/')[3];
  const customers = payload.selectedCustomer;

  const customerUserPermission = {
    customer_user: {
      user_id: userId,
      customers_ids: customers.map(item => ({
        customer_id: item.id,
      })),
    },
  };

  try {
    const createRequest = yield call(
      // handleUserCustomerPermissions,
      handleCustomerUserPermissions
      customerUserPermission
    );

    if (createRequest.errors) {
      return toast.error(
        'O usuário selecionado já tem relação com esse(s) clientes(s)'
      );
    }

    return yield put(
      showDuckNotification('Permissões criadas com sucesso', 'success')
    );
  } catch (error) {
    return toast.error(
      'O usuário selecionado já tem relação com esse(s) clientes(s)'
    );
  }
}



*/

function* updateOperator({ payload }) {
  const userId = payload.user.id;

  const newCustomerUser = {
    customer_user: {
      customers_ids: payload.checked.map(item => ({
        customer_id: item.customer_id,
      })),
    },
  };

  try {
    const request = yield call(
      updateCustomerUserOperator,
      userId,
      newCustomerUser
    );

    if (!request.error) {
      toast.success('Permissões atualizadas com sucesso');
      return history.goBack();
    }
  } catch (error) {
    return yield put(
      createShowPermissionError({
        message: 'Erro ao atualizar operador',
        type: 'error',
      })
    );
  }
  yield put(cleanCustomer());
  yield put(cleanUnit());
  yield put(cleanContract());
}

export function* saveOperatorValues() {
  yield takeEvery(SAVE_NEW_OPERATOR, saveNewOperator);
}

export function* updateOperatorPermissionsSaga() {
  yield takeLatest(UPDATE_OPERATOR_PERMISSIONS, updateOperator);
}
export function* fetchUserCustomerPermissions() {
  yield takeEvery(FETCH_CUSTOMERS_PERMISSIONS_CALL, fetchCustomerPermission);
}

export default function* rootSaga() {
  yield all([
    fork(saveOperatorValues),
    fork(updateOperatorPermissionsSaga),
    fork(fetchUserCustomerPermissions),
  ]);
}
